import React, { useState, useEffect } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Karussell from "../components/Karussell"
import Termin from "../components/Termin"
import Certificates from "../components/Certificates"
import WeReOpen from "../components/WeReOpen"
import styled from "styled-components"
import convert from "xml-js"
import { Link } from "gatsby"
import Michael from "../images/index/michael.png"
import Silhouette from "../images/index/silhouette.png"
import Deva from "../images/index/deva.png"
import Vorbeugung from "../images/index/vorbeugung.jpg"
import Kontaktlinsen from "../images/index/kontaktlinsen.jpg"
import Heimberatung from "../images/index/heimberatung.jpg"
import Logo from "../assets/schwarz-optik-logo.svg"
import { BlogEntry, RootObject, Item } from "../types/blog"

const IndexKarussellWrapper = styled.div`
  position: relative;
`
const StyledImage = styled.img`
  width: 120px;
`

const Index: React.FC = () => {
  const [isFetching, setisFetching] = useState(true)
  const [blogData, setBlogData] = useState<BlogEntry[]>([])

  useEffect(() => {
    fetch(`https://www.gesundheitsoptik.ch/?feed=post-teaser`)
      .then(response => response.text())
      .then(parsed =>
        convert.xml2js(parsed, { compact: true, nativeType: true })
      )
      .then((data: convert.ElementCompact) => {
        let blogEntries: BlogEntry[] = []
        data.rss.channel.item.map((item: Item) => {
          const blogEntry: BlogEntry = {
            guid: item.guid["_text"],
            title: item.title["_text"],
            link: item.link["_text"],
            enclosure: item.enclosure ? item.enclosure["_attributes"].url : "",
            supertitle: "Thema des Monats",
          }
          blogEntries.push(blogEntry)
        })
        setBlogData(blogEntries)
        setisFetching(false)
      })
  }, [])

  const siteTitle = "SCHWARZ Optik"

  let imgArticleHigh = {
    backgroundImage: `url(${Vorbeugung})`,
    //eingefügt wegen placeholder
    backgroundSize: "cover",
  }
  let imgArticle = {
    backgroundImage: `url(${Kontaktlinsen})`,
  }
  let imgArticle1 = {
    backgroundImage: `url(${Heimberatung})`,
  }

  return (
    <Layout title={siteTitle}>
      <SEO title={"Startseite – Seit 1992 in Heiden"} />
      <IndexKarussellWrapper>
        {blogData.length > 0 && <Karussell gallery={blogData} />}
        <Certificates />
        <WeReOpen />
      </IndexKarussellWrapper>
      <Termin />
      <section className="wrapper greyColor">
        <div className="team">
          <div className="team-item">
            <div className="team-item-image">
              <img src={Michael} alt="Michael Schwarz" />
            </div>
            <div className="team-item-title">Michael Schwarz</div>
            <div className="team-item-subtitle">Eidg. Dipl. Augenoptiker</div>
            <div className="team-item-text">
              Optometrist Fellow of the Optometric Extention Program at OEP-F
            </div>
          </div>
          <div className="team-item">
            <div className="team-item-image">
              <img src={Deva} alt="Deva Schwarz" />
            </div>
            <div className="team-item-title">Deva Schwarz</div>
            <div className="team-item-subtitle">Administration</div>
            <div className="team-item-text">
              die freundliche Stimme am Telefon
            </div>
          </div>
          <div className="team-item">
            <div className="team-item-image">
              <img src={Silhouette} alt="Vakanz" />
            </div>
            <div className="team-item-title">Lehrstelle frei</div>
            <div className="team-item-subtitle">Augenoptik EFZ</div>
            <div className="team-item-text">3 Jahre</div>
          </div>
        </div>
      </section>

      <section className="wrapper callout">
        <h2 style={{ textAlign: "center" }}>Sehen heisst sich wohlfühlen</h2>
        <p>
          Mit seinem kompetenten Team versteht sich SCHWARZ Optik als
          Fachunternehmen für die Vorsorge und Beratung zu allen Fragen der
          Optimierung des Sehens. SCHWARZ Optik – Seit 1992 in Heiden.
        </p>
        <StyledImage src={Logo} title="SCHWARZ Optik" />
      </section>
      <section className="wrapper greyColor imgArticles">
        <article className="imgArticle high" style={imgArticleHigh}>
          <Link to="/dienstleistungen/augenvorsorge/">
            <div className="imgArticle-text">
              Vorbeugende Beurteilung der Sehfunktion
            </div>
          </Link>
        </article>
        <article className="imgArticle" style={imgArticle}>
          <Link to="/angebot/kontaktlinsen/">
            <div className="imgArticle-text">Kontaktlinsen aber richtig</div>
          </Link>
        </article>
        <article className="imgArticle" style={imgArticle1}>
          <Link to="/dienstleistungen/heimberatung/">
            <div className="imgArticle-text">Heimberatung</div>
          </Link>
        </article>
      </section>
    </Layout>
  )
}

export default Index
