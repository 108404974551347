import React, { useState, useEffect } from "react"
import styled from "styled-components"
import MapMarker from "../assets/map-marker.svg"
import { Link } from "gatsby"

const StyledWeReOpen = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  max-width: 1000px;
  z-index: 999998;
  width: 100%;

  @media (min-width: 1200px) {
    margin: 0 calc(50% - 600px);
  }
`
const WeReOpenInner = styled.div`
  font-size: 31px;
  font-family: "Helvetica Neue LT W05_87 Hv Cn", sans-serif;
  padding: 40px;
  color: white;
  text-align: center;

  a {
    color: white;
  }

  @media (min-width: 920px) {
    text-align: left;
  }
  img {
    width: 30px;
    position: relative;
    top: 9px;
    left: 5px;
  }
`

const WeReOpen: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    let date = new Date()
    let year = date.getFullYear()
    let month = date.getMonth()
    let weekDay = date.getDay()
    let day = date.getDate()
    let hours = date.getHours()
    let minutes = date.getMinutes()

    if (weekDay > 1 && weekDay < 6) {
      // Dienstag bis Freitag
      // 08:30-12:00 und 13:30-18:30
      let morningOpen = new Date(year, month, day, 8, 30)
      let morningShut = new Date(year, month, day, 12, 0)
      let afternoonOpen = new Date(year, month, day, 13, 30)
      let afternoonShut = new Date(year, month, day, 18, 30)
      if (
        (date >= morningOpen && date <= morningShut) ||
        (date >= afternoonOpen && date <= afternoonShut)
      ) {
        setIsOpen(true)
      }
    } else if (weekDay == 6) {
      // Samstag
      // 08:30-12:00 und 13:30-16:00
      let morningOpen = new Date(year, month, day, 8, 30)
      let morningShut = new Date(year, month, day, 12, 0)
      let afternoonOpen = new Date(year, month, day, 13, 30)
      let afternoonShut = new Date(year, month, day, 16, 0)

      if (
        (date >= morningOpen && date <= morningShut) ||
        (date >= afternoonOpen && date <= afternoonShut)
      ) {
        setIsOpen(true)
      }
    }
  }, [])
  return (
    <StyledWeReOpen className="wrapper">
      <WeReOpenInner>
        {isOpen && (
          <Link to="/schwarz-optik/">
            Wir haben geöffnet <img src={MapMarker} />
          </Link>
        )}
      </WeReOpenInner>
    </StyledWeReOpen>
  )
}

export default WeReOpen
