import React from "react"
import styled from "styled-components"
import Swiper from "react-id-swiper"
import "swiper/swiper-bundle.min.css"
import SwiperCore, { Autoplay } from "swiper"
SwiperCore.use([Autoplay])
import { Link } from "gatsby"

const StyledKarussell = styled.section`
  z-index: 99999;
  background-color: #00aa9b;
  position: relative;

  @media (min-width: 920px) {
    margin-top: 110px;
  }
`
const StyledKarussellItem = styled.div`
  height: 665px;
  position: relative;

  & > a {
    display: block;
    width: 100%;
    height: 100%;
  }
  &:after {
    display: block;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    opacity: 0.98;
    background: linear-gradient(210deg, transparent 60%, #00aa9b 100%);
    z-index: 99997;
    pointer-events: none;
  }

  &:before {
    display: block;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    opacity: 0.98;
    background: linear-gradient(350deg, transparent 80%, #00aa9b 100%);
    z-index: 99997;
    pointer-events: none;
  }
`

const HeroTextWrapper = styled.div`
  z-index: 99999;
  position: absolute;
  bottom: 30px;
  left: 30px;
  right: 30px;

  h2 {
    font-family: "Helvetica Neue LT W05_57 Cond", sans-serif;
    margin-bottom: 0;
    color: white;
    z-index: 999999;
  }
  h1 {
    display: block;
    font-size: 42px;
    color: white;
    z-index: 999999;
    text-align: center;
  }

  @media (min-width: 800px) {
    h2 {
      font-family: "Helvetica Neue LT W05_57 Cond", sans-serif;
      font-size: 22px;
    }
    h1 {
      font-size: 72px;
      text-align: left;
    }
  }

  @media (min-width: 1020px) {
    right: 210px;
  }
`
const HeroImage = styled.div`
  height: 665px;
  width: 100%;
  background-size: cover;
  background-position: center;
`

const SliderOptions = {
  grabCursor: false,
  loop: true,
  autoplay: {
    delay: 5000,
    disableOnInteraction: false,
    waitForTransition: false,
  },
  shouldSwiperUpdate: true,
}

interface KarussellProps {
  gallery: []
}

const Karussell: React.FC<KarussellProps> = ({ gallery }) => {
  let detailPage

  return (
    <StyledKarussell className="wrapper hero">
      <Swiper {...SliderOptions}>
        {gallery
          .slice(0, 4)
          .map(({ supertitle, title, enclosure, link }, index) => {
            detailPage = link
            detailPage = detailPage.split(".ch")[1]
            detailPage = encodeURI(detailPage)
            return (
              <StyledKarussellItem key={title + index}>
                <Link to={`/blog/?${detailPage}`} title={title}>
                  <HeroImage style={{ backgroundImage: `url(${enclosure})` }} />
                  <HeroTextWrapper>
                    <h2>{supertitle}</h2>
                    <h1>{title}</h1>
                  </HeroTextWrapper>
                </Link>
              </StyledKarussellItem>
            )
          })}
      </Swiper>
    </StyledKarussell>
  )
}
export default Karussell
