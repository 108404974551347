import React, { useEffect, useRef } from "react"
import styled from "styled-components"
import Frequentlens from "../assets/frequentlens.png"
import Gesundheitsoptik from "../assets/gesundheitsoptik-zertifiziert.svg"

const StyledCerticates = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 999998;
  width: 1200px;
  display: none;

  @media (min-width: 1025px) {
    display: block;
  }
`
const CerticatesInner = styled.div`
  position: absolute;
  right: 0;
  height: 665px;
  width: 205px;
  padding: 20px 10px;
  background-color: rgba(255, 255, 255, 0.4);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`

const Certicate = styled.div`
  width: 140px;

  a {
    display: block;
    height: 100%;
  }
`

const Certificates: React.FC = () => {
  const certificateContainer = useRef<HTMLDivElement>(null)
  useEffect(() => {
    if (null !== certificateContainer.current) {
      const homepageScripts = document.createElement("script")
      homepageScripts.id = "swissqualiQuest"
      homepageScripts.src =
        "https://v2.swissqualiquest.ch/seals/widget/717?lang=de&align=portrait&view=all&size=medium&style=32&link=1"
      certificateContainer.current.appendChild(homepageScripts)
    }
  }, [])

  return (
    <StyledCerticates className="wrapper hero">
      <CerticatesInner>
        <Certicate>
          <a href="https://www.frequentlens.ch/" target="_blank">
            <img src={Frequentlens} />
          </a>
        </Certicate>
        <Certicate>
          <a href="https://www.gesundheitsoptik.ch/" target="_blank">
            <img src={Gesundheitsoptik} />
          </a>
        </Certicate>
        <Certicate ref={certificateContainer} />
      </CerticatesInner>
    </StyledCerticates>
  )
}

export default Certificates
